import { CHART_TYPE } from "../constants";

const getRandomColor = () => {
  var letters = "0123456789ABCDEF".split("");
  var color = "#";
  for (var i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

const calculateDatasets = ({ data, chartType }) => {
  let datasets = null;

  switch (chartType) {
    case CHART_TYPE.ATTACK:
      datasets = [
        {
          id: "total",
          label: "total",
          data: data?.data?.total?.values,
          borderColor: "rgb(255, 99, 132)",
          backgroundColor: "rgba(255, 99, 132, 0.5)",
        },
      ];
      break;
    case CHART_TYPE.DOMAINS:
      datasets = data?.rankingEntries?.map((entry, idx) => {
        return {
          id: idx,
          label: entry.domain,
          data: [entry.rank - entry.rankChange, entry.rank],
          borderColor: getRandomColor(),
          backgroundColor: getRandomColor(),
        };
      });
      break;
    case CHART_TYPE.TRAFFIC:
      datasets = Object.keys(data?.data).map((key, idx) => {
        return {
          id: idx,
          label: key,
          data: data?.data?.[key]?.values,
          borderColor: idx % 2 ? "rgb(255, 99, 132)" : "rgb(53, 162, 235)",
          backgroundColor:
            idx % 2 ? "rgba(255, 99, 132, 0.5)" : "rgba(53, 162, 235, 0.5)",
        };
      });
      break;
    default:
      break;
  }

  return datasets;
};

const getOptions = ({ chartType }) => {
  let options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Chart Title",
      },
    },
  };

  switch (chartType) {
    case CHART_TYPE.ATTACK:
      options.plugins.title.text = "Layer 3 DDoS attack";
      options.scales = {
        y: {
          ticks: {
            format: {
              style: "percent",
            },
          },
        },
      };
      break;
    case CHART_TYPE.DOMAINS:
      options.plugins.title.text = "Popular Domains Ranking";
      break;
    case CHART_TYPE.TRAFFIC:
      options.plugins.title.text = "Traffic Change";
      break;
    default:
      break;
  }

  return options;
};

const getLabels = ({ data, chartType }) => {
  let labels;

  switch (chartType) {
    case CHART_TYPE.ATTACK:
      labels = data?.data?.total?.timestamps;
      break;
    case CHART_TYPE.DOMAINS:
      labels = ["30 days ago", "Now"];
      break;
    case CHART_TYPE.TRAFFIC:
      labels = data?.data?.http?.timestamps;
      break;
    default:
      break;
  }

  return labels;
};

export const processData = ({ data, chartType }) => {
  return {
    datasets: calculateDatasets({ data, chartType }),
    options: getOptions({ chartType }),
    labels: getLabels({ data, chartType }),
  };
};
