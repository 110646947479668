import React from "react";

// MUI
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";

// Components
import AttackLayerChart from "../charts/AttackLayerChart";
import PopularDomainsChart from "../charts/PopularDomainsChart";
import TrafficChangeChart from "../charts/TrafficChangeChart";
import GridElement from "../widgets/GridElement";

const HomePage = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2}>
        <GridElement>
          <AttackLayerChart />
        </GridElement>
        <GridElement>
          <TrafficChangeChart />
        </GridElement>
        <GridElement>
          <PopularDomainsChart />
        </GridElement>
      </Grid>
    </Box>
  );
};

export default HomePage;
