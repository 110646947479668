import React, { useEffect, useState } from "react";

// Components
import AlertMessage from "../widgets/AlertMessage";
import LoadingSpinner from "../widgets/LoadingSpinner";
import LineChart from "../widgets/LineChart";

// Utils
import { processData } from "../../utils";

// API Client
import apiClient from "../../api_client";

// Constants
import { CHART_TYPE } from "../../constants";

const AttackLayerChart = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);
  const [data, setData] = useState(null);

  useEffect(() => {
    apiClient
      .get("/attack-layer3")
      .then(({ data }) => {
        setData(data);
      })
      .catch(() => {
        setError(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  if (error) return <AlertMessage />;

  if (isLoading) return <LoadingSpinner />;

  const { datasets, options, labels } = processData({
    data,
    chartType: CHART_TYPE.ATTACK,
  });

  return <LineChart options={options} labels={labels} datasets={datasets} />;
};

export default AttackLayerChart;
