import React from "react";
import PropTypes from "prop-types";

// Components
import CircularProgress from "@mui/material/CircularProgress";

const LoadingSpinner = ({ color }) => {
  return <CircularProgress color={color} />;
};

LoadingSpinner.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary"]).isRequired,
};

LoadingSpinner.defaultProps = {
  color: "secondary",
};

export default LoadingSpinner;
