import React from "react";
import PropTypes from "prop-types";

// Components
import { Line } from "react-chartjs-2";

// ChartJS
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const LineChart = ({ options, labels, datasets }) => {
  return (
    <Line
      options={options}
      data={{
        labels,
        datasets,
      }}
    />
  );
};

LineChart.propTypes = {
  options: PropTypes.object.isRequired,
  labels: PropTypes.arrayOf(PropTypes.string).isRequired,
  datasets: PropTypes.array.isRequired,
};

export default LineChart;
