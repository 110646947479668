import React from "react";
import PropTypes from "prop-types";

// Components
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";

const AlertMessage = ({ severity, title, message }) => {
  return (
    <Alert severity={severity}>
      <AlertTitle>{title}</AlertTitle>
      {message}
    </Alert>
  );
};

AlertMessage.propTypes = {
  severity: PropTypes.oneOf(["error", "warning", "info", "success"]).isRequired,
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
};

AlertMessage.defaultProps = {
  severity: "error",
  title: "Error",
  message: "There was an error fetching data from the API",
};

export default AlertMessage;
