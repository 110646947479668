import React from "react";
import PropTypes from "prop-types";

// Components
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";

function GridElement({ children }) {
  return (
    <Grid item xs={12}>
      <Paper elevation={2}>{children}</Paper>
    </Grid>
  );
}

GridElement.propTypes = {
  children: PropTypes.node.isRequired,
};

export default GridElement;
