// Separate API client for modularity
import axios from "axios";

// Configs
import { axiosConfig } from "../configs";

const apiClient = axios.create({
  baseURL: axiosConfig.baseURL,
});

export default apiClient;
